export default function Footer(){
    return (
        <div style={{ backgroundColor:'#00354C' }}>
            <footer className='container py-4 mt-5'>
                <div className='row text-light'>
                    <div className='col-lg-9 col-xs-9 text-start'>
                        <p>© Copyright 2024 | Managed By Freese and Nichols, Inc.</p>
                    </div>
                    <div className="col-lg-3 col-xs-3 text-end">
                        <p>Last Updated: 9/20/2024</p>
                    </div>
                    
                </div>
            </footer>
        </div>
    )
}